<template>
  <div class="fixed top-0 left-0 z-50 h-full w-full">
    <div
      class="fixed top-0 left-0 h-full w-full bg-black opacity-25"
      @click="close"
    ></div>

    <div
      class="animation-default h-fill-available absolute top-0 right-0 flex h-screen w-full max-w-lg flex-col overflow-y-auto bg-white animation-modalSlideSide animation-bezier animation-once"
    >
      <div
        class="border-b sticky top-0 z-20 flex h-12 items-stretch border-neutral-50 bg-white text-neutral-600"
      >
        <slot name="tabs"></slot>

        <button
          class="focus:outline-none ml-auto flex w-12 items-center justify-center text-neutral-600 transition-all duration-200 hover:text-primary-900"
          @click="close"
        >
          <IconClose />
        </button>
      </div>
      <div class="flex-grow flex flex-col overflow-y-scroll">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Fix for webkit mobile browsers */
.h-fill-available {
  height: -webkit-fill-available !important;
}
</style>
