<template>
  <div :class="{ 'dateinput-align-right': alignRight }">
    <div v-if="label" class="mb-1 block text-sm font-medium text-neutral-700">
      {{ label }}
    </div>
    <datepicker
      :placeholder="placeholder"
      :value="value"
      :disabled-dates="{ to: disableDatesUntil }"
      :monday-first="true"
      @input="update"
    ></datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Date,
      required: false,
      default: null,
    },
    disableDatesUntil: {
      type: Date,
      required: false,
      default: () => {
        return new Date();
      },
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style>
.vdp-datepicker input {
  @apply border block h-12 w-full rounded-sm border-neutral-100 bg-white px-4 text-sm text-neutral-900 transition-all duration-300;
}
.vdp-datepicker input::placeholder {
  @apply text-neutral-400;
}
.vdp-datepicker input:focus {
  @apply outline-none border-neutral-300;
}
.vdp-datepicker .vdp-datepicker__calendar .cell.selected {
  @apply bg-primary-600 text-white;
}
.vdp-datepicker .vdp-datepicker__calendar .cell.selected:hover {
  @apply border-primary-600 bg-primary-600;
}

.vdp-datepicker
  .vdp-datepicker__calendar
  .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker
  .vdp-datepicker__calendar
  .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker
  .vdp-datepicker__calendar
  .cell:not(.blank):not(.disabled).year:hover {
  @apply border-neutral-100;
}

.vdp-datepicker .vdp-datepicker__calendar .cell {
  @apply text-neutral-900;
}

.vdp-datepicker .vdp-datepicker__calendar .disabled {
  @apply text-neutral-400;
}

.vdp-datepicker .day__month_btn {
  @apply font-medium;
}

.vdp-datepicker .vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker .vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker .vdp-datepicker__calendar header .up:not(.disabled):hover {
  @apply bg-neutral-0;
}

.vdp-datepicker .vdp-datepicker__calendar .cell.day-header {
  @apply text-neutral-300;
}

.dateinput-align-right .vdp-datepicker__calendar {
  @apply right-0;
}
</style>
