<template>
  <div
    :class="[
      'border-t border-neutral-50 first:border-transparent',
      { 'p-5 md:p-8': defaultPadding },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    defaultPadding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
